<template>
    <b-container id="galery-categories">
        <b-row align-h="center" align-self="center">
            <b-col class="category" cols="12" sm="12" md="6" lg="4" xl="2" v-for="category in categories">
                <b-container>
                    <b-row align-h="center">
                        <b-link :to="{ name: 'CategoryPage', params: { name: category.category_name }}" class="category-title">
                            {{ category.category_name  }}
                        </b-link>
                    </b-row>
                    <b-row align-h="center">
                        <b-link :to="{ name: 'CategoryPage', params: { name: category.category_name }}" class="category-picture">
                            <b-img center :src="category.url"></b-img>
                        </b-link>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
export default {

    name: 'GaleryCategoriesPage',
    data () {
        return {
            categories: []
        }
    },
    methods: {
        getFrontPage(){
            this.$http.get('https://cwxo41u99h.execute-api.eu-central-1.amazonaws.com/Prod/first-picture-in-category/Galerie')
            .then(function(response){
                this.categories = response.body;
            });
        }
    }, created: function(){
        this.getFrontPage();

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#galery-categories {
    margin-top: 9em;
}

.category {
    padding-top: 2em;
}

.category-title {
    padding-bottom: 0.5em;
}

img {
    max-height: 11em;
    margin-top: 0;
    border: 2px solid #fff !important;
    box-shadow: 2px 2px 2px #a3a3a3 !important;
}

img:hover {

    box-shadow: 2px 2px 6px #000 !important;
}

#galery-categories {
    max-width: 100%;
    min-height: 70vh;
}
</style>
