<template>
    <b-container id="galery-categories">
        <b-row align-h="center" align-self="center">
            <b-col cols="12" xl="7">
                <b-link :to="{ name: 'CategoryPage', params: { name: currentCalender.category_name }}" class="category-picture">
                    <b-img right :src="currentCalender.url" alt="noch kein bild in der kategorie" class="current-calender"></b-img>
                </b-link>
            </b-col>
            <b-col cols="12" xl="2">
                {{ currentCalender.description }}
            </b-col>
            <b-col cols="2"></b-col>
            <b-col class="category" cols="12" sm="12" md="6" lg="4" xl="2" v-for="category in oldCalenders">
                <b-link :to="{ name: 'CategoryPage', params: { name: category.category_name }}" class="category-picture">
                    <b-img center :src="category.url" alt="noch kein bild in der kategorie" class="old-calender"></b-img>
                </b-link>
            </b-col>
        </b-row>
    </b-container>

</template>
<script>
export default {
    name: 'CalenderPage',
    data () {
        return {
            categories: []
        }
    },
    methods: {
        getCalendersWithPictures(){
            this.$http.get('https://cwxo41u99h.execute-api.eu-central-1.amazonaws.com/Prod/first-picture-in-category/Kalender')
            .then(function(response){
                this.categories = response.body;
            });
        }
    }, created: function(){
        this.getCalendersWithPictures();
    },
    computed: {
        currentCalender: function () {
            return this.categories[0]
        },
        oldCalenders: function () {
            //this makes a deep copy of an JS array
            var oldC = JSON.parse(JSON.stringify( this.categories ));
            oldC.splice(0,1);
            console.log(oldC)
            return oldC;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#galery-categories {
    padding-top: 3em;
}

.category-title {
    padding-bottom: 0.5em;
}

.current-calender {
    width: 700px;
    max-width: 100%;
}

.old-calender {
    max-height: 11em;
    margin-top: 4em;
}
img {
    border: 2px solid #fff !important;
    box-shadow: 1px 1px 5px #a3a3a3 !important;
}

img:hover {

    box-shadow: 2px 2px 6px #000 !important;
}


#galery-categories {
    max-width: 100%;
    min-height: 70vh;
}
</style>
