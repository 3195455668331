<template>
    <b-container>
        <b-row align-h="center">
            <span id="pic">
                <b-img :src="pictures[i].url" class=""></b-img>
            </span>
        </b-row>
        <b-row id="navigate-picture">
            <b-col cols="12" sm="12" md="7"offset-lg="1">
                <span id="title">
                    {{ pictures[i].title }} 
                </span>
            </b-col>
            <b-col cols="3" sm="2" md="1" offset="6" offset-sm="8" offset-md="1" >
                <b-link v-show="pictures.length > 1" @click="nextPicture">zurück</b-link>
            </b-col>
            <b-col cols="3" sm="2" md="1" >
                <b-link v-show="pictures.length > 1" @click="prevPicture">vor</b-link>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'GaleryPage',
    data () {
        return {
            i : 0,
            pictures: []
        }
    },
    methods: {
        getGaleryCategory(id){
            this.$http.get("https://cwxo41u99h.execute-api.eu-central-1.amazonaws.com/Prod/pictures-in-category/" + this.$route.params.name)
                .then(function(response){

                    this.pictures = response.body;
                    //this.frontPagePicture = JSON.parse(response.body);
                });
        },
        prevPicture(){
            if (this.i < this.pictures.length -1) {
                this.i++;
            } else {
                this.i = 0;
            }
        },
        nextPicture(){
            if (this.i <= 0) {
                this.i = this.pictures.length -1;
            } else {
                this.i--;
            }
        }
    },
    watch: {
        '$route': 'getGaleryCategory'
    },
    created: function(){
        this.getGaleryCategory();
    } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#navigate-picture {
    margin-top: 0.5em;
}

#pic {
    display: inline-table;
}

img {
    margin-top: 4em;
    margin-bottom: 1em;
    border: 2px solid #fff !important;
    box-shadow: 2px 2px 5px #a3a3a3 !important;
    width: 1000px;
    max-width: 100%;
}

</style>
