<template>
	<b-container>
		<b-row align-h="center">
			<span class="impressum">
				<h4>Impressum:</h4>
				</br>
				<h5>Verantwortlich für die Inhalte:</h5>
				</br>
				Torsten Niese</br> 
				Schlieperstraße 36</br> 
				13507 Berlin</br> 
				</br>
				E-Mail: t.niese@.posteo.de</br></br>
				<h5>Fotos auf www.torstenniese.com, Kommerzielle Nutzung, Erwerb der Bilder</h5> 
				</br>
				Die Rechte an den auf dieser Seite veröffentlichten und von mir aufgenommenen Fotos liegen – sofern nicht anders ausgewiesen – bei mir, Torsten Niese.</br> 
				Es ist nicht gestattet, diese Fotos – insbesondere für kommerzielle Zwecke – ohne meine schriftliche Erlaubnis weiterzuverwenden oder an anderer Stelle zu veröffentlichen.</br> 
				Für weitere Informationen zur Möglichkeit, die Rechte für die kommerzielle Veröffentlichung vereinzelter Fotografien in Print und Web zu erwerben, bitte ich um Kontaktaufnahme mit mir.</br> 
				</br>
				Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen</br> 
				wir keine Haftung für die Inhalte externer Links. Für den Inhalt der</br> 
				verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</br> 
				</br>
				Webentwickler: Jonny Rimek</br>

			</span>
		</b-row>
	</b-container>
</template>

<script>
export default {
	name: 'ImpressumPage',
	data () {
		return {
		}
	},
	methods: {
	}        
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.impressum {
	padding-top: 3em;
	
}
</style>
