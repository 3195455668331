<template>
    <b-container id="about">
        <b-row align-h="center">
            Work in Progress
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'AboutPage',
    data () {
        return {
        }
    },
    methods: {
    }        
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
