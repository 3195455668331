<template>
    <div id="app">
        <b-navbar toggleable="lg" >

            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

            <b-navbar-brand @click="hideGalery()" :to="{ name: 'FrontPage' }" id="name" style="color:#450b1c;" active-class="false">
                TORSTEN NIESE 
            </b-navbar-brand>

            <b-collapse is-nav id="nav_collapse">

                <b-navbar-nav>
                    <b-nav-item @click="showGalery()" class="nav-options" :to="{ name: 'GaleryPage' }">
                        Galerie
                    </b-nav-item>
                    <b-nav-item :to="{ name: 'CategoryPage', params: { name: 'Ferne' }}" class="nav-galery-categories" v-if="show_galery">
                        Ferne
                    </b-nav-item>
                    <b-nav-item :to="{ name: 'CategoryPage', params: { name: 'Gestern' }}" class="nav-galery-categories" v-if="show_galery">
                        Gestern
                    </b-nav-item>
                    <b-nav-item :to="{ name: 'CategoryPage', params: { name: 'Klang' }}" class="nav-galery-categories" v-if="show_galery">
                        Klang
                    </b-nav-item>
                    <b-nav-item :to="{ name: 'CategoryPage', params: { name: 'Mensch' }}" class="nav-galery-categories" v-if="show_galery">
                        Mensch
                    </b-nav-item>
                    <b-nav-item :to="{ name: 'CategoryPage', params: { name: 'Zauber' }}" class="nav-galery-categories" v-if="show_galery">
                        Zauber
                    </b-nav-item>
                    <!--<b-nav-item class="nav-options" @click="hideGalery()" :to="{ name: 'CalenderPage' }">
                        Kalender
                    </b-nav-item>
                    -->
                    <b-nav-item-dropdown text="Kontakt" class="nav-options">
                        <b-dropdown-item @click="hideGalery()" :to="{ name: 'AboutPage' }">
                            Über
                        </b-dropdown-item>
                        <b-dropdown-item @click="hideGalery()" :to="{ name: 'ImpressumPage' }">
                            Impressum
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                </b-navbar-nav>
            </b-collapse>

        </b-navbar>

        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    data ()  {
        return {
            show_galery: false
        }
    },
    methods: {
        showGalery(){
            this.show_galery = true;
        },        hideGalery(){
            this.show_galery = false;
        }
    }
}
</script>

<style>
html {
    position: relative;
    min-height: 100vh;
}

body {
    margin-bottom: 60px !important;
    background-color: #F7F5F1 !important;
    color: #a0a0a0 !important;
    min-height: 90vh;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 80vh;
}

#name {
    margin-left: 1em;
    margin-top: -0.3em;
    font-size: 1.45em;
    color: #7a0000 !important;
}
#app nav {
    background-color: #F7F5F1;
    padding-top: 2em;
}


#nav_collapse {
    margin-left: 12em;
}

.dropdown-menu {
    background-color: #F7F5F1 !important;
}

.dropdown-item{

    background-color: #F7F5F1 !important;
}

.dropdown-item.active {
    background-color: #F7F5F1 !important;
}

.dropdown-item:hover {
    background-color: #F7F5F1 !important;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px; /* Set the fixed height of the footer here*/
    line-height: 40px; /* Vertically center the text there*/ 
    background-color: #F7F5F1;
}
nav a {
    font-size: 14pt;
}
a{
    color: #a0a0a0 !important;
}

a:hover {
    color: #7a0000  !important;
    text-decoration: none !important;
}

.active {
    color: #7a0000 !important;
}

nav .nav-options .active {
    color: #7a0000 !important;
}

nav .nav-options a:hover{
    color: #7a0000 !important;
}


nav .nav-options a{
    color: #636363 !important;
}

</style>
