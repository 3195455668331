<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <b-carousel :interval="15000"
                            v-model="slide">
                    <b-carousel-slide   v-for="Picture in Pictures" 
                                        :img-src="Picture.url">
                    </b-carousel-slide>
                </b-carousel>
            </div>
        </div>
    </div>

</template>

<script>
export default {

    name: 'FrontPage',
    data () {
        return {
            Pictures: []
        }
    },
    methods: {
      getFrontPage(){
            //this.$http.get('http://api.torstenniese.com:8000/v1/pictures-front-page')
            this.$http.get('https://cwxo41u99h.execute-api.eu-central-1.amazonaws.com/Prod/front-page-pictures')
            .then(function(response){

                this.Pictures = response.body;
                //this.frontPagePicture = JSON.parse(response.body);
            });
        }
    }, created: function(){
        this.getFrontPage();
  
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.carousel {
    margin-top: 3em;
    border: 2px solid #fff !important;
    box-shadow: 2px 2px 5px #a3a3a3 !important;
}
</style>
