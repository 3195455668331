import Vue from 'vue'

import BootstrapVue from "bootstrap-vue"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueRouter from 'vue-router'
import App from './App.vue'

import AboutPage from './components/AboutPage.vue'
import GaleryPage from './components/GaleryPage.vue'
import CalenderPage from './components/CalenderPage.vue'
import CategoryPage from './components/CategoryPage.vue'
import ImpressumPage from './components/ImpressumPage.vue'
import FrontPage from './components/FrontPage.vue'

import vueResource from 'vue-resource'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(vueResource)
Vue.config.productionTip = false

const routes = [

     {
      path: '/',
      name: 'FrontPage',
      component: FrontPage
    },
    {
        path: '/galerie',
        name: 'GaleryPage',
        component: GaleryPage
    },
      {
      path: '/kalender',
      name: 'CalenderPage',
      component: CalenderPage
    },
    {
      path: '/category/:name',
      name: 'CategoryPage',
      component: CategoryPage
    },
      {
      path: '/impressum',
      name: 'ImpressumPage',
      component: ImpressumPage
    },
      {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage
    }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
})
